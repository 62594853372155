import * as React from 'react';
import Button from '@mui/material/Button';
import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TransitionProps } from '@mui/material/transitions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class IFrameLinkDialog extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onClick(e) {
        if (this.props.openUrl) {
            window.open(this.props.openUrl)
            this.props.clearUrl()
        }
    }

    onCloseClick(e) {
        this.props.clearUrl()
    }

    render() {
        return (
            <span>
                {/* <Dialog open={this.props.openUrl != null} onClose={this.onCloseClick}>
                    <iframe src={this.props.openUrl} title="Pay"></iframe>
                </Dialog> */}
                <Dialog
                    fullScreen
                    open={this.props.openUrl != null}
                    onClose={this.onCloseClick}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.onCloseClick}
                                aria-label="close"
                                sx = {{ml: "auto", mr: "-12"}}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <iframe style={{ width: "100vw", height: "100vh" }} src={this.props.openUrl} title="Pay"></iframe>
                </Dialog>
            </span>
        );
    }
}

export default IFrameLinkDialog