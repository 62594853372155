import Button from '@mui/material/Button';
import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

class FullScreenDialog extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onClick(e) {
        if (this.props.setFullScreen) {
            this.props.setFullScreen(true)
        }
    }

    onCloseClick(e) {
        this.props.setFullScreen(false)
    }

    render() {
        return (
            <span>
                <Dialog open={this.props.open} onClose={this.onCloseClick}>
                    <DialogTitle>Enter fullScrren mode?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Get better experience in full screen mode
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClick} autoFocus>Ok</Button>
                        <Button onClick={this.onCloseClick}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export default FullScreenDialog