import Button from '@mui/material/Button';
import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

class OpenLinkDialog extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    onClick(e) {
        if (this.props.openUrl) {
            window.open(this.props.openUrl)
            this.props.clearUrl()
        }
    }

    onCloseClick(e) {
        this.props.clearUrl()
    }

    render() {
        return (
            <span>
                <Dialog open={this.props.openUrl != null} onClose={this.onCloseClick}>
                    <DialogTitle>Tip</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            click "ok" redirect to new page
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClick} autoFocus>Ok</Button>
                        <Button onClick={this.onCloseClick}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

export default OpenLinkDialog