import Button from '@mui/material/Button';
import { Component } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';

class AddToHomeScreenButton extends Component {
    constructor() {
        super();
        this.state = {
            deferredPrompt: null
        };
        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
    }
    componentDidMount() {
        let deferredPrompt;

        window.addEventListener("beforeinstallprompt", (e) => {
            console.log(
                "🚀 ~ file: AddToHomeScreenButton.js:46 ~ AddToHomeScreenButton ~ window.addEventListener ~ beforeinstallprompt"
            );
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            navigator.getInstalledRelatedApps().then((relatedApps) => {
                console.log("🚀 related app: " + relatedApps)
                for (let i = 0; i < relatedApps.length; i++) {
                    console.log(relatedApps[i]);
                    if(relatedApps[i].url == "https://win777vip.com") {
                        return
                    }
                }
                deferredPrompt = e;
                console.log(
                    "🚀 ~ file: AddToHomeScreenButton.js:22 ~ AddToHomeScreenButton ~ window.addEventListener ~ deferredPrompt",
                    deferredPrompt
                );
                this.setState({ deferredPrompt });
            })
        });
    }

    onClick(e) {
        // hide our user interface that shows our A2HS button
        // addBtn.style.display = "none";
        // Show the prompt
        const { deferredPrompt } = this.state;

        console.log(
            "🚀 ~ file: AddToHomeScreenButton.js:49 ~ AddToHomeScreenButton ~ onClick ~ deferredPrompt",
            deferredPrompt
        );
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            console.log(
                "🚀 ~ file: AddToHomeScreenButton.js:53 ~ AddToHomeScreenButton ~ deferredPrompt.userChoice.then ~ choiceResult",
                choiceResult
            );
            console.log(
                "🚀 ~ file: AddToHomeScreenButton.js:55 ~ AddToHomeScreenButton ~ deferredPrompt.userChoice.then ~ choiceResult.outcome",
                choiceResult.outcome
            );
            if (choiceResult.outcome === "accepted") {
                console.log("User accepted the A2HS prompt");
            } else {
                console.log("User dismissed the A2HS prompt");
            }
            this.setState({ deferredPrompt: null });
        });
    }

    onCloseClick(e) {
        this.setState({ deferredPrompt: null });
    }

    render() {
        const { deferredPrompt } = this.state;

        return (
            <span>
                {!!deferredPrompt && this.props.open && (
                    <Grow in={!!deferredPrompt && this.props.open}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            class-name=".a2hs"
                        >
                            <Button
                                variant="contained"
                                sx={{ m: 2, p: 2 }}
                                onClick={this.onClick}
                            >
                                Add to home screen
                            </Button>

                            <Button
                                variant="contained"
                                sx={{ m: 2, p: 2 }}
                                onClick={this.onCloseClick}
                            >
                                Close
                            </Button>
                        </Box>
                    </Grow>
                )}
            </span>
        );
    }
}

export default AddToHomeScreenButton